import React from "react";
import image1 from "./assets/img/logo-top.png";
import logo1 from "./assets/img/c-logo-1.png";
import logo2 from "./assets/img/c-logo-2.png";
import logo3 from "./assets/img/c-logo-3.png";
import logo4 from "./assets/img/c-logo-4.png";
import logo5 from "./assets/img/c-logo-5.png";
import about1 from "./assets/img/about-left.png";
import aboutIcon1 from "./assets/img/about-icon-1.png";
import aboutIcon2 from "./assets/img/about-icon-2.png";
import aboutIcon3 from "./assets/img/about-icon-3.png";
import flagIcon1 from "./assets/img/flag-1.png";
import flagIcon2 from "./assets/img/flag-2.png";
import flagIcon3 from "./assets/img/flag-3.png";
import flagIcon4 from "./assets/img/flag-4.png";
import flagIcon5 from "./assets/img/flag-5.png";
import token1 from "./assets/img/token-top.png";
import token2 from "./assets/img/token-bottom.png";
import Roadmap1 from "./assets/img/roadmap-1.png";
import Roadmap2 from "./assets/img/roadmap-2.png";
import Roadmap3 from "./assets/img/roadmap-4.png";
import Roadmap4 from "./assets/img/roadmap-5.png";
import Mobile1 from "./assets/img/Mobile.png";


const Index2=()=>{
    return(
        <>
  <title>Cyptian</title>
  {/* Required meta tags */}
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  {/* carousel CSS */}
  <link rel="stylesheet" href="assets/css/owl.carousel.min.css" />
  {/*header icon CSS */}
  <link rel="icon" href="assets/img/fabicon.png" />
  {/* animations CSS */}
  <link rel="stylesheet" href="assets/css/animate.min.css" />
  {/* font-awsome CSS */}
  <link rel="stylesheet" href="assets/css/font-awesome.min.css" />
  {/* Bootstrap CSS */}
  <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
  {/* mobile menu CSS */}
  <link rel="stylesheet" href="assets/css/slicknav.min.css" />
  {/*css animation*/}
  <link rel="stylesheet" href="assets/css/animation.css" />
  {/*css animation*/}
  <link
    rel="stylesheet"
    href="assets/css/material-design-iconic-font.min.css"
  />
  {/* style CSS */}
  <link rel="stylesheet" href="style.css" />
  {/* responsive CSS */}
  <link rel="stylesheet" href="assets/css/responsive.css" />
  {/*header area start*/}
  <div
    className="header-area wow fadeInDown header-absolate"
    id="nav"
    data-0="position:fixed;"
    data-top-top="position:fixed;top:0;"
    data-edge-strategy="set"
  >
    <div className="container">
      <div className="row">
        <div className="col-4 d-block d-lg-none">
          <div className="mobile-menu" />
        </div>
        <div className="col-4 col-lg-2 ">
          <div className="logo-area">
            <a href="#">
              <img src={image1} alt="" />
            </a>
          </div>
        </div>
        <div className="col-4 col-lg-8 d-none d-lg-block">
          <div className="main-menu text-center">
            <nav>
              <ul id="slick-nav">
                <li>
                  <a className="scroll" href="#home">
                    home
                  </a>
                  <ul>
                    <li>
                      <a href="/">Version 1</a>
                    </li>
                    <li>
                      <a href="/index">Version 2</a>
                    </li>
                    <li>
                      <a href="blog.html">blog</a>
                    </li>
                    <li>
                      <a href="single-blog.html">single blog</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="scroll" href="#about">
                    About
                  </a>
                </li>
                <li>
                  <a className="scroll" href="#Paper">
                    White Paper
                  </a>
                </li>
                <li>
                  <a className="scroll" href="#token">
                    Token Sale
                  </a>
                </li>
                <li>
                  <a className="scroll" href="#roadmap">
                    Roadmap
                  </a>
                </li>
                <li>
                  <a className="scroll" href="#team">
                    Team
                  </a>
                </li>
                <li>
                  <a className="scroll" href="#apps">
                    APP
                  </a>
                </li>
                <li>
                  <a className="scroll" href="#faq">
                    FAQ
                  </a>
                </li>
                <li>
                  <a className="scroll" href="#contact">
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-4 col-lg-2 text-right">
          <a href="#" className="logibtn gradient-btn">
            login
          </a>
        </div>
      </div>
    </div>
  </div>
  {/*header area end*/}
  {/*welcome area start*/}
  <div className="welcome-area v2 wow fadeInUp" id="home">
    <div id="particles-js" />
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 align-self-center">
          <div className="welcome-right">
            <div className="welcome-text">
              <h1>
                Fast Growing ICO Agency for Blockchain Investors and Founders{" "}
              </h1>
              <h4>
                Sifting through teaspoons of clay and sand scraped from the
                floors of caves, German researchers have managed.
              </h4>
            </div>
            <div className="welcome-btn">
              <a href="#" className="gradient-btn v2 mr-20">
                Register for the ICO
              </a>
              <a href="#" className="gradient-btn v2">
                Download Whitepaper
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5 offset-1">
          <div className="v2 welcome-progress">
            <h4>ico ends in</h4>
            <div className="progress-time">
              <div className="row">
                <div className="col">
                  <span id="days" className="timeOne" />
                  <h5>days</h5>
                </div>
                <div className="col">
                  <span id="hours" className="timeTwo" />
                  <h5>hours</h5>
                </div>
                <div className="col">
                  <span id="minutes" className="timeTrhee" />
                  <h5>minutes</h5>
                </div>
                <div className="col">
                  <span id="seconds" className="timeFour" />
                  <h5>seconds</h5>
                </div>
              </div>
            </div>
            <div className="space-10" />
            <div className="prgress-bar">
              <div className="row">
                <div className="col">
                  <div className="single-prgress-w">
                    <p>goals</p>
                  </div>
                </div>
                <div className="col">
                  <div className="single-prgress-w text-right">
                    <p>$ 100 m</p>
                  </div>
                </div>
              </div>
              <div className="progress v2">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={70}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: "70%" }}
                >
                  <span className="sr-only">70% Complete</span>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="single-prgress-b">
                    <p>BTC Collected : 120,250</p>
                  </div>
                </div>
                <div className="col">
                  <div className="single-prgress-b text-right">
                    <p>Total : 250,000</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-10" />
            <a href="#" className="gradient-btn">
              Invest in ico
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*welcome area end*/}
  {/*about area start*/}
  <div className="about-area wow fadeInUp v2" id="about">
    <div className="space-30" />
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="heading">
            <h5>We are featured in</h5>
          </div>
          <div className="space-30" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="logo-carousel owl-carousel text-center">
            <div className="single-logo-wrapper">
              <div className="single-item">
                <img src={logo1} alt="" />
              </div>
            </div>
            <div className="single-logo-wrapper">
              <div className="single-item">
                <img src={logo2} alt="" />
              </div>
            </div>
            <div className="single-logo-wrapper">
              <div className="single-item">
                <img src={logo3} alt="" />
              </div>
            </div>
            <div className="single-logo-wrapper">
              <div className="single-item">
                <img src={logo4} alt="" />
              </div>
            </div>
            <div className="single-logo-wrapper">
              <div className="single-item">
                <img src={logo5} alt="" />
              </div>
            </div>
            <div className="single-logo-wrapper">
              <div className="single-item">
                <img src={logo3} alt="" />
              </div>
            </div>
            <div className="single-logo-wrapper">
              <div className="single-item">
                <img src={logo2} alt="" />
              </div>
            </div>
            <div className="single-logo-wrapper">
              <div className="single-item">
                <img src={logo5} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-90" />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="about-mid-img">
            <img src={about1} alt="" />
          </div>
        </div>
        <div className="col-12 col-md-6 align-self-center">
          <div className="heading">
            <h5>We are featured in</h5>
          </div>
          <div className="about-mid-text">
            <h1>A Platform for Exchange Cyrptocurrency and shares</h1>
            <div className="space-10" />
            <p>
              Mauna Loa, the biggest volcano on Earth half the Island of Hawaii.
              Just 35 miles to the northeast, Mauna Kea, known to native
              Hawaiians as Mauna a Wakea, rises nearly 14,000 feet above sea
              level. If they are so close together, how did they develop in two
              parallel tracks .Sifting through teaspoons of clay and sand
              scraped from the floors of caves.
            </p>
          </div>
          <div className="space-30" />
          <a href="#" className="gradient-btn v2 about-btn">
            {" "}
            <i className="fa fa-send-o" /> join us on telegraph
          </a>
        </div>
      </div>
    </div>
    <div className="space-90" />
  </div>
  {/*about area end*/}
  {/*single about area start*/}
  <div className="single-about-area wow fadeInUp">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <a href="#" className="single-about">
            <div className="single-about-img">
              <img src={aboutIcon1} alt="" />
            </div>
            <div className="single-about-text">
              <h4>Exciting Opportunity</h4>
              <p>
                The recording starts with the patter of a summer squall. Later,
                a drifting tone like that of a not quite tuned in radio station
                rises and for a while drowns
              </p>
            </div>
          </a>
        </div>
        <div className="col-lg-4">
          <a href="#" className="single-about">
            <div className="single-about-img">
              <img src={aboutIcon2} alt="" />
            </div>
            <div className="single-about-text">
              <h4>Vetted ICO Marketplace</h4>
              <p>
                The recording starts with the patter of a summer squall. Later,
                a drifting tone like that of a not quite tuned in radio station
                rises and for a while drowns
              </p>
            </div>
          </a>
        </div>
        <div className="col-lg-4">
          <a href="#" className="single-about">
            <div className="single-about-img">
              <img src={aboutIcon3} alt="" />
            </div>
            <div className="single-about-text">
              <h4>Diverse Profit Ways</h4>
              <p>
                The recording starts with the patter of a summer squall. Later,
                a drifting tone like that of a not quite tuned in radio station
                rises and for a while drowns
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  {/*single about area end*/}
  {/*Documentation area start*/}
  <div className="section-padding wow fadeInUp documentation-area" id="Paper">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="heading">
            <h5>Whitepaper</h5>
            <div className="space-10" />
            <h1>Download Documentation</h1>
          </div>
          <div className="space-60" />
        </div>
      </div>
      <div className="row text-center">
        <div className="col-6 col-lg">
          <div className="single-document">
            <div className="document-flag">
              <img src={flagIcon1} alt="" />
            </div>
            <button className="single-document-text">
              <span>English</span>
            </button>
          </div>
        </div>
        <div className="col-6 col-lg">
          <div className="single-document">
            <div className="document-flag">
              <img src={flagIcon2} alt="" />
            </div>
            <button className="single-document-text">
              <span>Spanish</span>
            </button>
          </div>
        </div>
        <div className="col-6 col-lg">
          <div className="single-document">
            <div className="document-flag">
              <img src={flagIcon3} alt="" />
            </div>
            <button className="single-document-text">
              <span>Russian</span>
            </button>
          </div>
        </div>
        <div className="col-6 col-lg">
          <div className="single-document">
            <div className="document-flag">
              <img src={flagIcon4} alt="" />
            </div>
            <button className="single-document-text">
              <span>Arabic</span>
            </button>
          </div>
        </div>
        <div className="col-6 col-lg">
          <div className="single-document">
            <div className="document-flag">
              <img src={flagIcon5} alt="" />
            </div>
            <button className="single-document-text">
              <span>Portuguese</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*Documentation area end*/}
  {/*distibution-bg start*/}
  <div className="distibution-bg v2">
    {/*-distibution area start*/}
    <div className="distibution wow fadeInUp" id="token">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="heading">
              <h5>Token Distribution</h5>
              <div className="space-10" />
              <h1>initial distibution</h1>
            </div>
            <div className="space-60" />
          </div>
        </div>
        <div className="row">
          <div className="col-6 text-right">
            <div className="distibution-svg distibution-svg-1">
              <img src={token1} alt="" />
            </div>
          </div>
          <div className="col-6">
            <div className="distibution-d item-1">
              <ul>
                <li className="distibution-list-1">
                  <span>15% </span>Build Up Team
                </li>
                <li className="distibution-list-2">
                  <span>50% </span>ICO Investors
                </li>
                <li className="distibution-list-3">
                  <span>25% </span>Branding &amp; Marketing
                </li>
                <li className="distibution-list-4">
                  <span>10% </span>Bounty{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="space-90" />
        <div className="row">
          <div className="col-12 text-center">
            <div className="heading">
              <h5>Sale breakdown</h5>
              <div className="space-10" />
              <h1>Token Sales Contribution</h1>
            </div>
            <div className="space-90" />
          </div>
        </div>
        <div className="row">
          <div className="col-2 text-right">
            <div className="distibution-d distibution-d-2">
              <ul>
                <li className="distibution-list-5">
                  <span>40% </span>HR &amp; Development
                </li>
                <li className="distibution-list-6">
                  <span>30% </span>Branding &amp; Markting
                </li>
                <li className="distibution-list-7">
                  <span>20% </span>Posiblle Buyout
                </li>
                <li className="distibution-list-8">
                  <span>10% </span>Legal Advisory{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-10">
            <div className="distibution-svg distibution-svg-2">
              <img src={token2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="space-90" />
    </div>
    {/*-distibution area end*/}
  </div>
  {/*distibution-bg end*/}
  {/*roadmap area start*/}
  <div className="roadmap-area section-padding wow fadeInUp" id="roadmap">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="heading">
            <h5>history Timeline</h5>
            <div className="space-10" />
            <h1>Development Roadmap</h1>
          </div>
          <div className="space-60 d-none d-sm-block" />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="roadmap-carousel owl-carousel">
        <div className="roadmap-item">
          <div className="single-roadmap text-center road-left">
            <div className="single-roadmap-img">
              <img src={Roadmap1} alt="" />
            </div>
            <div className="space-30" />
            <div className="roadmap-text">
              <p>01.03.2017</p>
              <div className="space-10" />
              <h5>Concept and whitepaper</h5>
              <p>
                The recording starts with the patter of a summer squall. Later,
                a drifting tone like that of a in token.
              </p>
            </div>
          </div>
        </div>
        <div className="roadmap-item align-self-center">
          <div className="single-roadmap road-right">
            <div className="row">
              <div className="col-5 align-self-center">
                <div className="single-roadmap-img">
                  <img src={Roadmap2} alt="" />
                </div>
              </div>
              <div className="col-7">
                <div className="roadmap-text">
                  <p>21.06 .2017</p>
                  <h5>Recruitment of Our team</h5>
                  <p>
                    The recording starts with the patter of a summer squall.
                    Later, a drifting tone like that of a in token.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap-item">
          <div className="single-roadmap text-center road-left">
            <div className="single-roadmap-img">
              <img src={Roadmap3} alt="" />
            </div>
            <div className="space-30" />
            <div className="roadmap-text">
              <p>31.08.2017</p>
              <div className="space-10" />
              <h5>Core Development</h5>
              <p>
                The recording starts with the patter of a summer squall. Later,
                a drifting tone like that of a in token.
              </p>
            </div>
          </div>
        </div>
        <div className="roadmap-item align-self-center">
          <div className="single-roadmap road-right">
            <div className="row">
              <div className="col-5 align-self-center">
                <div className="single-roadmap-img">
                  <img src={Roadmap4} alt="" />
                </div>
              </div>
              <div className="col-7">
                <div className="roadmap-text">
                  <p>31.11.2017</p>
                  <h5>Main Development</h5>
                  <p>
                    The recording starts with the patter of a summer squall.
                    Later, a drifting tone like that of a in token.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap-item">
          <div className="single-roadmap text-center road-left">
            <div className="single-roadmap-img">
              <img src={Roadmap3} alt="" />
            </div>
            <div className="space-30" />
            <div className="roadmap-text">
              <p>31.08.2017</p>
              <div className="space-10" />
              <h5>Core Development</h5>
              <p>
                The recording starts with the patter of a summer squall. Later,
                a drifting tone like that of a in token.
              </p>
            </div>
          </div>
        </div>
        <div className="roadmap-item align-self-center">
          <div className="single-roadmap road-right">
            <div className="row">
              <div className="col-5 align-self-center">
                <div className="single-roadmap-img">
                  <img src={Roadmap4} alt="" />
                </div>
              </div>
              <div className="col-7">
                <div className="roadmap-text">
                  <p>31.11.2017</p>
                  <h5>Main Development</h5>
                  <p>
                    The recording starts with the patter of a summer squall.
                    Later, a drifting tone like that of a in token.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/*roadmap area end*/}
  {/*team-bg*/}
  <div className="team-bg">
    {/*team area start*/}
    <div className="team-area wow fadeInUp section-padding" id="team">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="heading">
              <h5>core team</h5>
              <div className="space-10" />
              <h1>Our Superman</h1>
            </div>
            <div className="space-60" />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="single-team">
              <div className="single-team-img">
                <img src="assets/img/superman-1.jpg" alt="" />
              </div>
              <div className="space-30" />
              <div className="single-team-content">
                <h3>William Delisle</h3>
                <div className="space-10" />
                <h6>FOUNDER &amp; CEO</h6>
              </div>
              <div className="space-10" />
              <div className="single-team-social">
                <ul>
                  <li>
                    <a className="ico-1" href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-2" href="#">
                      <i className="fa fa-dribbble" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-3" href="#">
                      <i className="fa fa-twitter " />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="single-team">
              <div className="single-team-img">
                <img src="assets/img/superman-2.jpg" alt="" />
              </div>
              <div className="space-30" />
              <div className="single-team-content">
                <h3>Julius Book</h3>
                <div className="space-10" />
                <h6>SOFTWARE ENGINEER</h6>
              </div>
              <div className="space-10" />
              <div className="single-team-social">
                <ul>
                  <li>
                    <a className="ico-1" href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-2" href="#">
                      <i className="fa fa-dribbble" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-3" href="#">
                      <i className="fa fa-twitter " />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="single-team">
              <div className="single-team-img">
                <img src="assets/img/superman-6.jpg" alt="" />
              </div>
              <div className="space-30" />
              <div className="single-team-content">
                <h3>Jessica Blair</h3>
                <div className="space-10" />
                <h6>MARKETING ANALYST</h6>
              </div>
              <div className="space-10" />
              <div className="single-team-social">
                <ul>
                  <li>
                    <a className="ico-1" href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-2" href="#">
                      <i className="fa fa-dribbble" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-3" href="#">
                      <i className="fa fa-twitter " />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="single-team">
              <div className="single-team-img">
                <img src="assets/img/superman-7.jpg" alt="" />
              </div>
              <div className="space-30" />
              <div className="single-team-content">
                <h3>Nancy Burns</h3>
                <div className="space-10" />
                <h6>Head of Design</h6>
              </div>
              <div className="space-10" />
              <div className="single-team-social">
                <ul>
                  <li>
                    <a className="ico-1" href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-2" href="#">
                      <i className="fa fa-dribbble" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-3" href="#">
                      <i className="fa fa-twitter " />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="team-area team small">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="heading">
              <h5>Advisory team</h5>
              <div className="space-10" />
              <h1>Advisory Board</h1>
            </div>
            <div className="space-60" />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="single-team">
              <div className="single-team-img">
                <img src="assets/img/superman-4.jpg" alt="" />
              </div>
              <div className="space-30" />
              <div className="single-team-content">
                <h3>Tricia Morgan</h3>
                <div className="space-10" />
                <h6>ADVISOR</h6>
              </div>
              <div className="space-10" />
              <div className="single-team-social">
                <ul>
                  <li>
                    <a className="ico-1" href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-2" href="#">
                      <i className="fa fa-dribbble" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-3" href="#">
                      <i className="fa fa-twitter " />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="single-team">
              <div className="single-team-img">
                <img src="assets/img/superman-5.jpg" alt="" />
              </div>
              <div className="space-30" />
              <div className="single-team-content">
                <h3>Kent Ransom</h3>
                <div className="space-10" />
                <h6>ADVISOR</h6>
              </div>
              <div className="space-10" />
              <div className="single-team-social">
                <ul>
                  <li>
                    <a className="ico-1" href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-2" href="#">
                      <i className="fa fa-dribbble" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-3" href="#">
                      <i className="fa fa-twitter " />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="single-team">
              <div className="single-team-img">
                <img src="assets/img/superman-6.jpg" alt="" />
              </div>
              <div className="space-30" />
              <div className="single-team-content">
                <h3>Edward Schultz</h3>
                <div className="space-10" />
                <h6>ADVISOR</h6>
              </div>
              <div className="space-10" />
              <div className="single-team-social">
                <ul>
                  <li>
                    <a className="ico-1" href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-2" href="#">
                      <i className="fa fa-dribbble" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-3" href="#">
                      <i className="fa fa-twitter " />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="single-team">
              <div className="single-team-img">
                <img src="assets/img/superman-7.jpg" alt="" />
              </div>
              <div className="space-30" />
              <div className="single-team-content">
                <h3>Betty Cyr</h3>
                <div className="space-10" />
                <h6>ADVISOR</h6>
              </div>
              <div className="space-10" />
              <div className="single-team-social">
                <ul>
                  <li>
                    <a className="ico-1" href="#">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-2" href="#">
                      <i className="fa fa-dribbble" />
                    </a>
                  </li>
                  <li>
                    <a className="ico-3" href="#">
                      <i className="fa fa-twitter " />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-30" />
    </div>
    {/*team area end*/}
    {/*apps area start*/}
    <div className="apps-area section-padding wow fadeInUp" id="apps">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 offset-1 align-self-center">
            <div className="heading">
              <h5>Advisory team</h5>
              <h1>Advisory Board</h1>
              <div className="space-20" />
              <p>
                Swimming hundreds of feet beneath the ocean’s surface in many
                parts of the world are prolific architects called giant
                larvaceans. These zooplankton are not particularly giant
                themselves but every day, they construct one or more spacious
                houses that can exceed .{" "}
              </p>
              <p>
                The recording starts with the patter of a summer squall. Later,
                a drifting tone like that of a not-quite-tuned-in radio station
                rises and for a while drowns out the patter.
              </p>
            </div>
            <div className="space-30" />
            <a href="#" className="gradient-btn apps-btn">
              {" "}
              <i className="zmdi zmdi-google-play" />
              Google Playstore
            </a>
            <a href="#" className="gradient-btn apps-btn apps-btn-2">
              {" "}
              <i className="zmdi zmdi-apple" />
              Apple Appstore
            </a>
          </div>
          <div className="col-12 col-lg-5 offset-1">
            <div className="apps-img">
              <img src={Mobile1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*apps area end*/}
    {/*faq area start*/}
    <div className="faq-area wow fadeInUp v2" id="faq">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="heading">
              <h5>faq</h5>
              <div className="space-10" />
              <h1>Frequently Asked Questions </h1>
            </div>
            <div className="space-60" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <div className="faq-list">
              <ul className="nav nav-pills" id="pills-tab">
                <li>
                  <a className="active" data-toggle="pill" href="#one">
                    General Questions
                  </a>
                </li>
                <li>
                  <a data-toggle="pill" href="#two">
                    ico
                  </a>
                </li>
                <li>
                  <a data-toggle="pill" href="#three">
                    token
                  </a>
                </li>
                <li>
                  <a data-toggle="pill" href="#four">
                    Cryptocurrency
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="space-50" />
      </div>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="one" role="tabpanel">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 offset-1">
                <div className="faq-collups">
                  <div id="accordion">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse1"
                            aria-expanded="true"
                          >
                            How to trade cryptocurrencies ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse1"
                        className="collapse show"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse2"
                            aria-expanded="false"
                          >
                            The risks of investing in ICO ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse2"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse3"
                            aria-expanded="false"
                          >
                            How to run an ICO ? Why should you invest ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse3"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse4"
                            aria-expanded="false"
                          >
                            If you could have any kind of pet, what would you
                            choose?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse4"
                        className="collapse"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="two" role="tabpanel">
          <div className="container">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="faq-collups">
                  <div id="parrent2">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse5"
                            aria-expanded="true"
                          >
                            How to trade cryptocurrencies ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse5"
                        className="collapse show"
                        data-parent="#parrent2"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse6"
                            aria-expanded="false"
                          >
                            The risks of investing in ICO ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse6"
                        className="collapse"
                        data-parent="#parrent2"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse7"
                            aria-expanded="false"
                          >
                            How to run an ICO ? Why should you invest ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse7"
                        className="collapse"
                        data-parent="#parrent2"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse8"
                            aria-expanded="false"
                          >
                            If you could have any kind of pet, what would you
                            choose?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse8"
                        className="collapse"
                        data-parent="#parrent2"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="three" role="tabpanel">
          <div className="container">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="faq-collups">
                  <div id="parrent3">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse9"
                            aria-expanded="true"
                          >
                            How to trade cryptocurrencies ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse9"
                        className="collapse show"
                        data-parent="#parrent3"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse10"
                            aria-expanded="false"
                          >
                            The risks of investing in ICO ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse10"
                        className="collapse"
                        data-parent="#parrent3"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse11"
                            aria-expanded="false"
                          >
                            How to run an ICO ? Why should you invest ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse11"
                        className="collapse"
                        data-parent="#parrent3"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse12"
                            aria-expanded="false"
                          >
                            If you could have any kind of pet, what would you
                            choose?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse12"
                        className="collapse"
                        data-parent="#parrent3"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="four" role="tabpanel">
          <div className="container">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="faq-collups">
                  <div id="parrent4">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse13"
                            aria-expanded="true"
                          >
                            How to trade cryptocurrencies ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse13"
                        className="collapse show"
                        data-parent="#parrent4"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse14"
                            aria-expanded="false"
                          >
                            The risks of investing in ICO ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse14"
                        className="collapse"
                        data-parent="#parrent4"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse15"
                            aria-expanded="false"
                          >
                            How to run an ICO ? Why should you invest ?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse15"
                        className="collapse"
                        data-parent="#parrent4"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapse16"
                            aria-expanded="false"
                          >
                            If you could have any kind of pet, what would you
                            choose?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse16"
                        className="collapse"
                        data-parent="#parrent4"
                      >
                        <div className="card-body">
                          <p>
                            A putrid, nicotine-shaded mist loomed over Beijing
                            on Thursday after a massive sandstorm slammed into
                            the Chinese capital bringing the latest airpocalypse
                            to this smog-choked city. Dozens of flights were
                            reportedly cancelled at Beijing’s airport, the
                            world’s second busiest, and authorities urged
                            residents to stay indoors after levels of PM10, a
                            tiny inhalable particle linked to a variety of lung
                            complaints.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-90" />
    </div>
    {/*faq area end*/}
  </div>
  {/*team bg area end*/}
  {/*community area start*/}
  <div className="community-area v2 wow fadeInUp section-padding" id="contact">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className="heading">
            <h5>contact</h5>
            <div className="space-10" />
            <h1>Drop Your Message</h1>
          </div>
          <div className="space-60" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5 offset-2">
          <div className="contact-form">
            <form action="#">
              <input type="text" placeholder="your name" />
              <div className="space-20" />
              <input type="email" placeholder="your email" />
              <div className="space-20" />
              <textarea
                name="text"
                id="message"
                cols={30}
                placeholder="Write your message"
                rows={5}
                defaultValue={""}
              />
            </form>
            <div className="space-20" />
            <a href="#" className="gradient-btn v2">
              Send message
            </a>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="contact-details">
            <div className="single-details">
              <small>For general enquires</small>
              <div className="space-10" />
              <a href="#">info@cyptian.com</a>
            </div>
            <div className="space-30" />
            <div className="single-details">
              <small>For general enquires</small>
              <div className="space-10" />
              <a href="#">info@cyptian.com</a>
            </div>
            <div className="space-30" />
            <div className="single-details">
              <small>anything else? just call us</small>
              <div className="space-10" />
              <a href="#">22588 268 2563 584</a>
            </div>
          </div>
        </div>
      </div>
      <div className="space-90" />
      <div className="row">
        <div className="col-12 text-center">
          <div className="heading">
            <h1>Join our community</h1>
          </div>
          <div className="space-60" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="single-community text-center">
            <ul>
              <li>
                <a className="google-plus" href="#">
                  <i className="fa fa-google-plus" />
                </a>{" "}
              </li>
              <li>
                <a className="linkedin" href="#">
                  <i className="fa fa-linkedin" />
                </a>
              </li>
              <li>
                <a className="dribbble" href="#">
                  <i className="fa fa-dribbble" />
                </a>
              </li>
              <li>
                <a className="github" href="#">
                  <i className="fa fa-github" />
                </a>
              </li>
              <li>
                <a className="behance" href="#">
                  <i className="fa fa-behance" />
                </a>
              </li>
              <li>
                <a className="youtube" href="#">
                  <i className="fa fa-youtube" />
                </a>
              </li>
              <li>
                <a className="twitter" href="#">
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li>
                <a className="flickr" href="#">
                  <i className="fa fa-flickr" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="space-90" />
    {/*footer area start*/}
    <div className="footera-area wow fadeInDown">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="single-footer">
              <div className="logo-area footer">
                <a href="#">
                  <img src={image1} alt="" />
                </a>
              </div>
              <div className="space-20" />
              <p>
                Swimming hundreds of feet beneath the ocean’s surface in many
                parts of the world are prolific architects called giant
                larvaceans.{" "}
              </p>
              <div className="space-10" />
              <p>
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                Copyright © All rights reserved | This template is made with{" "}
                <i className="fa fa-heart-o" aria-hidden="true" /> by{" "}
                <a href="https://colorlib.com" target="_blank">
                  Colorlib
                </a>
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-2">
            <div className="single-footer">
              <ul>
                <li>
                  <a href="#">About</a>
                </li>
                <li>
                  <a href="#">Token Sale</a>
                </li>
                <li>
                  <a href="#">Roadmap</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-lg-2">
            <div className="single-footer">
              <ul>
                <li>
                  <a href="#">White Paper</a>
                </li>
                <li>
                  <a href="#">Team</a>
                </li>
                <li>
                  <a href="#">APP</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="single-footer">
              <p>Subscribe to our Newsletter</p>
              <div className="space-20" />
              <div className="footer-form v2">
                <form action="#">
                  <input type="email" placeholder="Email Address" />
                  <a href="" className="gradient-btn subscribe">
                    GO
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*footer area end*/}
  </div>
  {/*community area end*/}
  {/* jquery 2.2.4 js*/}
  {/* popper js*/}
  {/* carousel js*/}
  {/* wow js*/}
  {/* bootstrap js*/}
  {/*skroller js*/}
  {/*mobile menu js*/}
  {/*particle s*/}
  {/* main js*/}
</>

    );
}

export default Index2;