import React,{useState, useEffect} from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { applyColorMode } from "./ColorMode";
import Contact from "./Components/contact";
import AnimLoader from "./Components/animloader";

const Contactpage=()=>{

    useEffect(()=>{
        document.title="Contact | Cogniglob"
        applyColorMode();
      },[]);
    

    // const colorvar= getColorMode();


    // const var1="-mode";
    // const var2=colorvar.concat(var1);
    return(
        <div>
            <Navbar />
            <AnimLoader/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Contactpage;