import React from "react";
import Testimonial from "./Components/Testimonial";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";

const TestimonialPage=()=>{
    return(
        <>
            <Navbar/>
            <Testimonial/>
            
        </>
    );
}

export default TestimonialPage;