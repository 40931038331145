import React, { useState, useEffect, useRef } from "react";
import "./Testimonial.css";
import axios from "axios";

const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [testimTimer, setTestimTimer] = useState(null);
  const [touchStartPos, setTouchStartPos] = useState(0); // Store touch start position
  const testimSpeed = 4500;
  const ignoreTouch = 30;

  const testimRef = useRef(null);
  const testimDotsRef = useRef([]);
  const testimContentRef = useRef([]);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  // Testimonial data array
  const testimonials = [
    {
      name: "Sarah L.",
      content:
        "Cogniglob revolutionized our IT infrastructure. Their solutions not only enhanced our system's performance but also fortified our security measures.",
      image: "https://via.placeholder.com/100x100.png?text=Image1",
    },
    {
      name: "James K.",
      content:
        "We partnered with Cogniglob for cloud migration, and the process was seamless. Their team provided us with a scalable solution that has greatly improved our operational agility.",
      image: "https://via.placeholder.com/100x100.png?text=Image2",
    },
    // Add more testimonials as needed
  ];

  

  useEffect(() => {
    const totalSlides = testimContentRef.current.length;

    const playSlide = (slide) => {
      testimContentRef.current.forEach((content, index) => {
        content.classList.remove("active", "inactive");
        testimDotsRef.current[index].classList.remove("active");
      });

      if (slide < 0) slide = totalSlides - 1;
      if (slide >= totalSlides) slide = 0;

      testimContentRef.current[slide].classList.add("active");
      testimDotsRef.current[slide].classList.add("active");

      setCurrentSlide(slide);

      // Reset timer with updated slide
      clearTimeout(testimTimer);
      setTestimTimer(setTimeout(() => playSlide(slide + 1), testimSpeed));
    };

    const handleArrowClick = (direction) => {
      const nextSlide = currentSlide + direction;
      playSlide(nextSlide);
    };

    leftArrowRef.current.addEventListener("click", () => handleArrowClick(-1));
    rightArrowRef.current.addEventListener("click", () => handleArrowClick(1));

    testimDotsRef.current.forEach((dot, index) => {
      dot.addEventListener("click", () => playSlide(index));
    });

    // Initialize the first slide
    playSlide(currentSlide);

    const handleKeyUp = (e) => {
      if (e.key === "ArrowLeft") handleArrowClick(-1);
      if (e.key === "ArrowRight") handleArrowClick(1);
    };
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      clearTimeout(testimTimer);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [currentSlide]);

  const handleTouchStart = (e) => {
    setTouchStartPos(e.changedTouches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndPos = e.changedTouches[0].clientX;
    const touchPosDiff = touchStartPos - touchEndPos;

    if (touchPosDiff > ignoreTouch) {
      rightArrowRef.current.click();
    } else if (touchPosDiff < -ignoreTouch) {
      leftArrowRef.current.click();
    }
  };

  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`https://us-central1-cogniglob-website1.cloudfunctions.net/api/testimonial`);
  //       setData(Object.values(response.data));
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  // if (!data.length) {
  //   return <p>Loading...</p>;
  // }


  return (
    <section
      id="testim"
      className="testim mb-4"
      ref={testimRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="wrap">
        <span
          id="right-arrow"
          className="arrow right fa fa-chevron-right"
          ref={rightArrowRef}
        />
        <span
          id="left-arrow"
          className="arrow left fa fa-chevron-left"
          ref={leftArrowRef}
        />

        <ul id="testim-dots" className="dots">
          {testimonials.map((_, index) => (
            <li
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              ref={(el) => (testimDotsRef.current[index] = el)}
            />
          ))}
        </ul>

        <div id="testim-content" className="cont">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? "active" : ""}`}
              ref={(el) => (testimContentRef.current[index] = el)}
            >
              <div className="img">
                <img src={testimonial.image} alt={testimonial.name} />
              </div>
              <h2>{testimonial.name}</h2>
              <p>{testimonial.content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
