import React,{useState, useEffect} from "react";
import { getColorMode } from "../ColorMode";
import axios from "axios";
import Demo from "./demo";
import "./Contact.css";

const Contact=()=>{

    const colorMode = getColorMode();

    const var1="-Contact-form";

    const var2=colorMode.concat(var1);

    const var3="-Contact-background";

    const var4= colorMode.concat(var3);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        contact: "",
        message: "",
      });

    const[data,setData]= useState('');
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState(""); 

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
          // Send form data to the backend
          const response = await axios.post("https://us-central1-cogniglob-website1.cloudfunctions.net/api/contactform", formData);
          console.log("Form submitted successfully", response.data);
    
          setSuccessMessage("Your message has been sent successfully!");
          setErrorMessage("");
         
          setFormData({
            name: "",
            email: "",
            contact: "",
            message: "",
          });
    
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        } catch (error) {
          console.error("Error submitting form:", error);
          setErrorMessage("There was an error sending your message. Please try again.");
        }
      };
    
      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await axios.get("https://us-central1-cogniglob-website1.cloudfunctions.net/api/contact");
            console.log('Fetched doctor data:', response.data); 
    
            setData(response.data);
          } catch (error) {
            console.error('Error fetching data from backend:', error);
          }
        };
        fetchData();
      },[]);
    

    return(
     
        <div className="background">
  <div className="container">
    <div className={`${var2} screen`}>
      <div className="screen-header">
        <div className="screen-header-left">
          <div className="screen-header-button close1" />
          <div className="screen-header-button maximize" />
          <div className="screen-header-button minimize" />
        </div>
        {/* <div className="screen-header-right">
          <div className="screen-header-ellipsis" />
          <div className="screen-header-ellipsis" />
          <div className="screen-header-ellipsis" />
        </div> */}
      </div>
      <div className="screen-body">
        <div className="screen-body-item left">
          <div className="app-title">
            <span>CONTACT US</span>
          </div>
          <div className="space-20"/>
          <div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3884.5013369695125!2d80.20046817508033!3d13.19381078714238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDExJzM3LjciTiA4MMKwMTInMTEuMCJF!5e0!3m2!1sen!2sin!4v1726747607203!5m2!1sen!2sin"
            width={300}
            height={150}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            style={{borderRadius:"20px"}}
          />

        </div>
          <div className="app-contact">CONTACT INFO : +919940190129</div>
          <div className="app-contact">EMAIL{"    "}: info@cogniglob.com</div>
        </div>
        <div className="screen-body-item">
          <div className="app-form">
          <form onSubmit={handleSubmit}>
            <div className="app-form-group">
              <input
                type="text"
                name="name"
                className="app-form-control"
                placeholder="NAME"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="app-form-group">
              <input type="email" name="email" className="app-form-control1" placeholder="EMAIL" value={formData.email}  onChange={handleChange}/>
            </div>
            <div className="app-form-group">
              <input type="text" name="contact" className="app-form-control" placeholder="CONTACT NO" value={formData.contact} onChange={handleChange}/>
            </div>
            <div className="app-form-group message">
              <input  type="text" name="message" className="app-form-control" placeholder="MESSAGE" value={formData.message} onChange={handleChange}/>
            </div>
            <div className="app-form-group buttons">
              
              <button type="submit" className="app-form-button">SEND</button>
            </div>
            </form>
            {successMessage && (
                <div  className="contactsuccess-message">
                  <p>{successMessage}</p>
                </div>
              )}
              {errorMessage && (
                  <div className="contacterror-message">
                    <p>{errorMessage}</p>
                  </div>
                )}
          </div>
        </div>
      </div>
    </div>
  
  </div>
  
  
</div>

    );
}

export default Contact;