import React, { useState, useEffect } from "react";
import "./animloader.css";

const AnimLoader = () => {
  const [percent, setPercent] = useState(0);

  const loadingText = ["C", "o", "g", "n", "i", "g", "l", "o", "b"];

  useEffect(() => {
    let interval;
    if (percent < 100) {
      interval = setInterval(() => {
        setPercent((prevPercent) => {
          const nextPercent = prevPercent + 1;
          return nextPercent <= 100 ? nextPercent : 100;
        });
      }, 28); // Adjust speed as needed
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [percent]);

  return (
    <>
      <div id="loader-wrapper2" style={{ display: percent < 100 ? "flex" : "none" }}>
        <div className="animation-container">
          <div className="text-animation">
            <div className="center">
              {/* <div className="circle">
                {[...Array(12)].map((_, index) => (
                  <div key={index} className="dot3" />
                ))}
              </div>
              <div className="circle2">
                {[...Array(12)].map((_, index) => (
                  <div key={index} className="dot4" />
                ))}
              </div> */}

            </div>
            {/* <div className="loadingScreen">
              <h3 className="loadingScreen__text">
                {loadingText.map((val, index) => (
                  <span key={index} className="loadingScreen__text__span">
                    {val}
                  </span>
                ))}
              </h3>
            </div> */}
          </div>
          <div className="right-container">
          
                
                
            <div id="row loader2">
              <div className="loader"/>
              <div id="percentage2">{percent}%</div>
              <div id="loader-inner2" />
            </div>
          </div>
        </div>
        <div className="area">
          <ul className="circles">
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
        </div>
      </div>
    </>
  );
};

export default AnimLoader;
