import React, { useState, useEffect } from 'react';
import './Service3rd.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintbrush, faCode } from '@fortawesome/free-solid-svg-icons';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
import { useParams } from "react-router-dom";
import axios from "axios";
import { getColorMode } from '../ColorMode';

const CardComponent = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://us-central1-cogniglob-website1.cloudfunctions.net/api/thirdservice/${id}`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  if (!data) {
    return <p>Loading...</p>; 
  }

  const icons = [faPaintbrush, faCode, faSearchengin]; // Cycle through available icons

  const colorMode= getColorMode();
  const var1 = "-containerrd";
  const var2 = colorMode.concat(var1);

  return (
    <section className={`${var2} containerrd`}>
      <div className="card__containerrd">
        {data.map((card, index) => (
          card && (
            <div className="card__bx11" style={{ '--clr': card.color, height:"400px" }} key={index}>
              <div className="card__data1">
                <div className="card__icon">
                  <FontAwesomeIcon icon={icons[index % icons.length]} />
                  {/* <div dangerouslySetInnerHTML={{ __html: card.Icon }} /> */}
                </div>
                <div className="card__content1">
                  <h3>{card.Title}</h3> {/* Ensure property names match your API response */}
                  <p className='text-justify'>{card.Description}</p> {/* Ensure property names match your API response */}
                </div>
              </div>
            </div>
          )
        ))}
      </div>
    </section>
  );
};

export default CardComponent;
