import React,{useState, useEffect} from "react";
import "./ServiceEx1.css";
import { useParams } from "react-router-dom";
import axios from "axios";

const ServiceExample1=()=>{

  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(()=>{
    const fetchData = async () =>{
      try{
        const response= await axios.get(`https://us-central1-cogniglob-website1.cloudfunctions.net/api/secondservice/${id}`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  },[id]);

  if (!data) {
    return <p>Loading...</p>; 
  }

    return(
        <section id="customer" className="section overflow-hidden" style={{backgroundColor:"#0c0c0d"}}>
  <div className="servicontainer4" style={{marginTop:"20px"}}>
    <div
      className="page-padding mb-4"
      style={{
        opacity: 1,
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        transformStyle: "preserve-3d"
      }}
    >
      <div className="v-flex gap-64">
        <div
          id="w-node-_27ba9e62-e568-11de-110f-f7cba7538572-299e36c2"
          className="service_s2-wrapper"
        >
       
          <div className="service_s2-layout">
            <div
              id="w-node-_27ba9e62-e568-11de-110f-f7cba7538575-299e36c2"
              className="text-block"
            >
              OVERVIEW
            </div>
            <h1 className="h-44 service_s2-heading">{data.PointsTitle}</h1>
                <div id="w-node-_27ba9e62-e568-11de-110f-f7cba7538579-299e36c2" className="service_s2-content-wrapper">
                  {/* Loop through Points array */}
                  {data.Points && data.Points.filter(point => point !== null).map((point, index) => (
                    <div key={index} id="w-node-_27ba9e62-e568-11de-110f-f7cba753857a-299e36c2" className="service_s2-content">
                      <div id="w-node-_27ba9e62-e568-11de-110f-f7cba753857b-299e36c2" className="number">
                        {index + 1 < 10 ? `0${index + 1}` : index + 1}
                      </div>
                      <div className="p1">
                        {point}
                      </div>
                    </div>
                  ))}
             
              <div className="blob"></div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    );
}

export default ServiceExample1;