export const getColorMode = () => {
  const savedMode = localStorage.getItem('colorMode');
  if (savedMode) return savedMode;

  const hour = new Date().getHours();
  return hour >= 6 && hour < 18 ? 'light' : 'dark';
};

export const toggleColorMode = () => {
  const currentMode = getColorMode();
  const newMode = currentMode === 'light' ? 'dark' : 'light';
  localStorage.setItem('colorMode', newMode);
  document.body.classList.remove('light', 'dark');
  document.body.classList.add(newMode);
  return newMode;
};

export const applyColorMode = () => {
  document.body.classList.add(getColorMode());
};
