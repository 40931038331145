import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getColorMode } from "../ColorMode";
import image2 from "../assets/img/welcome-img.png";  // Example image path
import animat1 from "../images/animationex.gif"; // Example image path
import NET from 'vanta/dist/vanta.net.min';
import * as THREE from 'three'; // Required by Vanta.js

const Welcome = () => {
  const colorMode = getColorMode();

  const var1 = "-welcome-text";
  const var2 = colorMode.concat(var1);

  const var3 = "-welcome-area";
  const var4 = colorMode.concat(var3);

  const [data, setData] = useState('');
  const vantaRef = useRef(null); 

  useEffect(() => {
   
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-cogniglob-website1.cloudfunctions.net/api/welcome");
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
   
  //   const vantaEffect = NET({
  //     el: vantaRef.current,
  //     THREE, 
  //     mouseControls: true,
  //     touchControls: true,
  //     gyroControls: false,
  //     minHeight: 600.00,
  //     minWidth: 200.00,
  //     scale: 1.00,
  //     scaleMobile: 1.00,
  //     color: 0x0a0ef1,
  //     backgroundColor: colorMode === 'dark' ? 0x070708 : 0xffffff, 
  //   });

  //   return () => {
  //     if (vantaEffect) vantaEffect.destroy();
  //   }; 
  // }, [colorMode]);

  return (
    <div className={`${var4} welcome-area wow fadeInUp`} id="home" style={{height:"800px"}}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 align-self-center">
            <div className="welcome-right">
              <div className={`${var2}`}>
                <h1 style={{ fontSize: "30px" }}>
                  {data.title}
                </h1>
                <h4 style={{ fontSize: "15px", textAlign: "justify" }}>
                  {data.description}
                </h4>
              </div>
              <div className="welcome-btn">
                <a href="#" className="gradient-btn v2 mr-20">
                  Register for the ICO
                </a>
                <a href="#" className="gradient-btn v2">
                  Download Whitepaper
                </a>
              </div>
           
            </div> 
          </div>
          <div className="col-12 col-md-6">
            <div className="welcome-img">
              <img src={data.image || animat1} alt="Welcome" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
