import React, { useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import "./demo.css";
import Image1 from "../images/CloudMigration.png";

const Demo=()=>{

  const [services, setServices] = useState([]);
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0); 
  const [canScrollPage, setCanScrollPage] = useState(false); 
  const [scrollAmount, setScrollAmount] = useState(0); 
  const scrollThreshold = 100; // Scroll amount required to move to next/prev slide

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-cogniglob-website1.cloudfunctions.net/api/services1"
        );
        const data = response.data;

        setServices(data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  const [services2, setServices2] = useState([]);

  useEffect(() => {
    const fetchServices2 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-cogniglob-website1.cloudfunctions.net/api/services2"
        );
        const data = response.data;

        setServices2(data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices2();
  }, []);

  const [services3, setServices3] = useState([]);

  useEffect(() => {
    const fetchServices3 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-cogniglob-website1.cloudfunctions.net/api/services3"
        );
        const data = response.data;

        setServices3(data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices3();
  }, []);

  const [services4, setServices4] = useState([]);
  
  useEffect(() => {
    const fetchServices4 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-cogniglob-website1.cloudfunctions.net/api/services4"
        );
        const data = response.data;

        setServices4(data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices4();
  }, []);

  const [services5, setServices5] = useState([]);
  
  useEffect(() => {
    const fetchServices5 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-cogniglob-website1.cloudfunctions.net/api/services5"
        );
        const data = response.data;

        setServices5(data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices5();
  }, []);

  const [services6, setServices6] = useState([]);
  
  useEffect(() => {
    const fetchServices6 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-cogniglob-website1.cloudfunctions.net/api/services6"
        );
        const data = response.data;

        setServices6(data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices6();
  }, []);


  const [services7, setServices7] = useState([]);
  
  useEffect(() => {
    const fetchServices7 = async () => {
      try {
        const response = await axios.get(
          "https://us-central1-cogniglob-website1.cloudfunctions.net/api/services7"
        );
        const data = response.data;

        setServices7(data);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices7();
  }, []);

  return(
    <>
      
      <section className="section service-v2">
    <div className="servicontainer3">
    <div className="servi" >
    <div
        className="service-v2-bottom-wrap"
      >
      <div className="service-v2-sticky-wrap">
      <div className="service-v2-main-wrap">
    <div className="service-v2-single-wrap section1">
      <div className="services-label">
        Services
      </div>
      <div className="service-v2-flex-wrap">
        <div className="service-v2-content-wrap">
          <div className="service-v2-card-details-wrap">
            <h2 className="service-v2-card-title">{services.Name}</h2>
            <p className="service-v2-card-details">{services.description}</p>
          </div>
          <div className="primary-button-wrapper">
          <a
            href={services.href}
            className="primary-button-wrap w-inline-block"
            >
            <div className="primary-button-text">Build Now</div>
            <img
                src="https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda02f_Icons.png"
                loading="lazy"
                alt=""
                className="primary-button-icon"
              />
          </a>
          </div>
        </div>
        <div className="service-v2-lottie-wrap _04">
          <div className="CloudMigration">
              <img
                src={services.Icon}
                alt={services.Name}
                
              />
          </div>
        </div>
      </div>
      
    </div>
    <div className="service-v2-single-wrap section2">
    <div className="services-label1">
        Services
      </div>
      <div className="service-v2-flex-wrap">
        <div className="service-v2-content-wrap">
          <div className="service-v2-card-details-wrap">
            <h2 className="service-v2-card-title">{services2.Name}</h2>
            <p className="service-v2-card-details">{services2.description}</p>
          </div>
          <div className="primary-button-wrapper">
      <a
        href={services2.href}
        className="primary-button-wrap w-inline-block"
      >
      <div className="primary-button-text">Build Now</div>
        <img
          src="https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda02f_Icons.png"
          loading="lazy"
          alt=""
          className="primary-button-icon"
          />
        </a>
        </div>
      </div>
     <div className="service-v2-lottie-wrap _05">
     <img
                src={services2.Icon}
                alt={services2.Name}
               
              />
     </div>
    </div>
    </div>
    <div className="service-v2-single-wrap section3">
    <div className="services-label2">
        Services
      </div>
      <div className="service-v2-flex-wrap">
        <div className="service-v2-content-wrap">
          <div className="service-v2-card-details-wrap">
            <h2 className="service-v2-card-title text-white">{services3.Name}</h2>
            <p className="service-v2-card-details text-white">{services3.description}</p>
          </div>
          <div className="primary-button-wrapper">
          <a href={services3.href} className="primary-button-wrap w-inline-block">
            <div className="primary-button-text">Defend Now</div>
              <img
                src="https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda02f_Icons.png"
                loading="lazy"
                alt=""
                className="primary-button-icon"
                />
            </a>
          </div>
        </div>
        <div className="service-v2-lottie-wrap _03">
        <img
                src={services3.Icon}
                alt={services3.Name}
                
              />
        </div>
      </div>
     
    </div>
    <div className="service-v2-single-wrap section4">
    <div className="services-label3">
        Services
      </div>
      <div className="service-v2-flex-wrap _01">
        <div className="service-v2-content-wrap _01">
          <div className="service-v2-card-details-wrap">
            <h2 className="service-v2-card-title">{services4.Name}</h2>
            <p className="service-v2-card-details">{services4.description}</p>
          </div>
          <div className="primary-button-wrapper">
            <a href={services4.href} className="primary-button-wrap w-inline-block">
            <div className="primary-button-text">Mordernize Now</div>
              <img
                src="https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda02f_Icons.png"
                loading="lazy"
                alt=""
                className="primary-button-icon"
              />
            </a>
            </div>
          </div>
          <div className="service-v2-lottie-wrap _01">
          <img
                src={services4.Icon}
                alt={services4.Name}
               
              />
          </div>
        </div>
    </div>
    <div className="service-v2-single-wrap section5">
    <div className="services-label4">
        Services
      </div>
      <div className="service-v2-flex-wrap">
        <div className="service-v2-content-wrap">
          <div className="service-v2-card-details-wrap">
            <h2 className="service-v2-card-title text-black">{services5.Name}</h2>
            <p className="service-v2-card-details text-black">{services5.description}</p>
          </div>
          <div className="primary-button-wrapper">
          <a href={services5.href} className="primary-button-wrap w-inline-block">
            <div className="primary-button-text">Automate Now</div>
              <img
                src="https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda02f_Icons.png"
                loading="lazy"
                alt=""
                className="primary-button-icon"
              />
          </a>
          </div>
        </div>
        <div className="service-v2-lottie-wrap _02">
        <img
                src={services5.Icon}
                alt={services5.Name}
              
              />
        </div>
      </div>
    </div>
    <div className="service-v2-single-wrap section6">
    <div className="services-label5">
        Services
      </div>
      <div className="service-v2-flex-wrap">
        <div className="service-v2-content-wrap">
          <div className="service-v2-card-details-wrap">
            <h2 className="service-v2-card-title text-black">{services6.Name}</h2>
            <p className="service-v2-card-details text-black">{services6.description}</p>
          </div>
          <div className="primary-button-wrapper">
            <a href={services6.href} className="primary-button-wrap w-inline-block">
            <div className="primary-button-text">Defend Now</div>
            <img
              src="https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda02f_Icons.png"
              loading="lazy"
              alt=""
              className="primary-button-icon"
              />
            </a>
          </div>
        </div>
        <div className="service-v2-lottie-wrap _06">
        <img
                src={services6.Icon}
                alt={services6.Name}
                
              />
        </div>
        </div>
    </div>
    <div className="service-v2-single-wrap section7">
    <div className="services-label6">
        Services
      </div>
      <div className="service-v2-flex-wrap">
        <div className="service-v2-content-wrap">
          <div className="service-v2-card-details-wrap">
            <h2 className="service-v2-card-title">{services7.Name}</h2>
            <p className="service-v2-card-details">{services7.description}</p>
          </div>
          <div className="primary-button-wrapper">
            <a href={services7.href} className="primary-button-wrap w-inline-block">
              <div className="primary-button-text">Mordernize Now</div>
              <img
                src="https://cdn.prod.website-files.com/65c7a9e15dc241d49fbd9ffe/65c7a9e15dc241d49fbda02f_Icons.png"
                loading="lazy"
                alt=""
                className="primary-button-icon"
                />
            </a>
          </div>
        </div>
        <div className="service-v2-lottie-wrap">
        <img
                src={services7.Icon}
                alt={services7.Name}
                
              />
        </div>
        </div>
        </div>
        <div className="service-dot-wrap">
              <div
                className="service-single-dot _01"
                style={{
                  willChange: "background",
                  backgroundColor: "rgb(34, 74, 232)"
                }}
              />
              <div
                className="service-single-dot _02"
                style={{
                  willChange: "background",
                  backgroundColor: "rgb(217, 217, 217)"
                }}
              />
              <div
                className="service-single-dot _03"
                style={{
                  willChange: "background",
                  backgroundColor: "rgb(217, 217, 217)"
                }}
              />
              <div
                className="service-single-dot _04"
                style={{
                  willChange: "background",
                  backgroundColor: "rgb(217, 217, 217)"
                }}
              />
              <div
                className="service-single-dot _05"
                style={{
                  willChange: "background",
                  backgroundColor: "rgb(217, 217, 217)"
                }}
              />
              <div
                className="service-single-dot _06"
                style={{
                  willChange: "background",
                  backgroundColor: "rgb(217, 217, 217)"
                }}
              />
              <div
                className="service-single-dot _07"
                style={{
                  willChange: "background",
                  backgroundColor: "rgb(217, 217, 217)"
                }}
              />
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  </section>
  
  </>
  );
}

export default Demo;