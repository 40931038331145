import React, { useState, useEffect } from "react";
import axios from "axios";
import { getColorMode } from "../ColorMode";

const Singleabout = () => {

  const colorMode = getColorMode();

  const var1="-single-about-area";
  const var2= colorMode.concat(var1);

  const var3="-container-card1";
  const var4= colorMode.concat(var3);

  const [data, setData] = useState(""); // Initialize as an empty array

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-cogniglob-website1.cloudfunctions.net/api/Singleabout");
        console.log("Fetched Single-about data:", response.data);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);

  const[data1,setData1]= useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-cogniglob-website1.cloudfunctions.net/api/Singleabout1");
        console.log("Fetched Single-about data:", response.data);
        setData1(response.data);
      } catch (error) {
        console.error("Error fetching data from backend:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={`${var2} single-about-area`}>
      <div className="container">
        <div className="row "  style={{marginTop:"100px"}}>
          <div className="singlecontainer col-lg-12">
            <div className={`${var4} container-card1 bg-blue-box`}>
              <div className="single-about-img">
                <div className="aboutcircle">
                  <div className="singleicon"
                    dangerouslySetInnerHTML={{ __html: data.Image }}
                  />
                </div>
              </div>
              <div className="single-about-text" style={{height: "200px"}}>
                <h4 style={{color:"white"}}>{data.Title}</h4>
                <p style={{color:"white",textAlign:"justify"}}>{data.description}</p>
              </div>
            </div>
            <div className={`${var4} container-card1 bg-green-box`}>
              <div className="single-about-img">
                <div className="aboutcircle">
                  <div className="singleicon"
                    dangerouslySetInnerHTML={{ __html: data1.Image }}
                  />
                </div>
              </div>
              <div className="single-about-text">
                <h4 style={{color:"white"}}>{data1.Title}</h4>
                <p style={{color:"white",textAlign:"justify"}}>{data1.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-90" />
    </div>
  );
};

export default Singleabout;
