import React,{useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import img1 from "../images/about-1.png";
import "./ServiceExample.css";
import axios from "axios";

const ServiceExample = () => {

  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(()=>{
    const fetchData = async () =>{
      try{
        const response= await axios.get(`https://us-central1-cogniglob-website1.cloudfunctions.net/api/firstservice/${id}`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  },[id]);

  if (!data) {
    return <p>Loading...</p>; 
  }

  return (
    <div className="servicefir container-fluid FAQ bg-light overflow-hidden py-5">
    
      <div className="blur-background1"></div>
      
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item border-0 mb-4">
                <div id="collapseOne" className="accordion-collapse collapse show">
                  <div className="accordion-body my-2">
                    <h5 className="firstservicehead">{data.Name}</h5>
                    <p style={{ textAlign: "justify" }}>
                      {data.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
            <div className="FAQ-img RotateMoveRight rounded">
              <img src={img1} className="img-fluid w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceExample;
