import React,{useState, useEffect} from "react";
import Navbar from "./Components/Navbar";
import { applyColorMode } from "./ColorMode";
import About from "./Components/about";
import Singleabout from "./Components/Single-about";
import Footer from "./Components/Footer";
import CoreTeam from "./Components/Team";

import CardComponent from "./Components/CardComponent";
import Team1 from "./Components/Team1.js";
import AnimLoader from "./Components/animloader.js";

const Aboutpage=()=>{

    useEffect(()=>{
        document.title="About | Cogniglob"
        applyColorMode();
      },[]);
    
    // const colorvar= getColorMode();
    // const var1="-mode";
    // const var2=colorvar.concat(var1);

    return(
        <div>
        
        <Navbar/>
        <About/>
        {/* <Singleabout/> */}
        <div className="team-bg">
        {/* <CoreTeam/> */}
        </div>
        <Singleabout/>
       
        <Team1/>
        {/* <CardComponent/> */}
        <AnimLoader/>
        <Footer/>
        </div>
    );
}

export default Aboutpage;