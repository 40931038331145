import React, { useState, useEffect } from "react";
import "../style.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '@fortawesome/fontawesome-free/css/all.css';
import { useParams } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getColorMode } from "../ColorMode";

const ReService = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://us-central1-cogniglob-website1.cloudfunctions.net/api/RemainService`);
        const filteredData = Object.values(response.data).filter(
          service => service && service.Icon && service.Name
        ); // Filter out empty or null items
        setData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  if (!data.length) {
    return <p>Loading...</p>;
  }

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow custom-arrow-next`}
        style={{ ...style, backgroundColor: "black", padding:"1px", borderRadius:"20px", width:"25px" }} // Set the background color here
        onClick={onClick}
      >
        <i className="fas fa-arrow-right" />
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow custom-arrow-prev`}
        style={{ ...style, backgroundColor: "black", padding:"1px", borderRadius:"20px", width:"25px"  }} // Set the background color here
        onClick={onClick}
      >
        <i className="fas fa-arrow-left" />
      </div>
    );
  };
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  

  const colorMode = getColorMode();

  const var1="-Remain-Service";

  const var2=colorMode.concat(var1);

  return (
    <>
      <div className="container-fluid Reservice py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 900 }}
          >
            <h2 className="mb-1 text-primary text-bold Reserviceh2" style={{textTransform: "capitalize"}}>CHECK OUT OUR OTHER SERVICES</h2>
          </div>
          <Slider {...settings}>
            {data.map((service, index) => (
              <div key={index}>
                <div className="service-itemre text-center rounded p-4" style={{height:"550px"}}>
                  <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                    <img
                      src={service.Icon}
                      alt={service.Name}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  <div className={`service-content ${var2}`}>
                    <h4 className="mb-4">{service.Name}</h4>
                    <p className="mb-4 text-justify">{service.description}</p>
                    <a
                      href={service.href}
                      className="Reservicebtn btn rounded-pill py-2 px-4"
                      style={{width:"150px",color:"black"}}
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default ReService;
