import React,{useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { applyColorMode } from "./ColorMode";
import DistributionArea from "./Components/DistributionArea";
import CoreTeam from "./Components/Team";
import MobileApp from "./Components/MobileApp";
import FAQ from "./Components/FAQ";

import Animation from "./Components/animation";
import Singleabout from "./Components/Single-about";
import Demo from "./Components/demo";
import ServiceExample from "./Components/ServiceExample";
import ServiceExample1 from "./Components/ServiceExample1";
import CardComponent from "./Components/CardComponent";
// import Testimonial from "./Components/Testimonial";
import ReService from "./Components/ReService";
import AnimLoader from "./Components/animloader";

const ServicePage=()=>{

    const { id } = useParams();

    useEffect(()=>{
        document.title="Service | Cogniglob"
        applyColorMode();
      },[]);
    

    // const colorvar= getColorMode();


    // const var1="-mode";
    // const var2=colorvar.concat(var1);

    return(
        <div >
        <Navbar/>
        {/* <Singleabout/> */}
        <div className="team-bg">
        {/* <CoreTeam/>
        <MobileApp/> */}
        
        </div>
        
        <ServiceExample/>
        <ServiceExample1/>
        <CardComponent/>
        <ReService/>
        {/* <Testimonial/> */}
        <div className="space-10"/>
       
       <AnimLoader/>
        <Footer/>
        </div>
    );
}

export default ServicePage;